/* Responsive Css */

@media #{$extra-xxl-device}{
  .hero-slider--business .slide-content {
      margin-top: 390px;
  }
  .hero-slider--business .slide-content h2 {
    font-size: 90px !important;
  } 
   .endtoendWrap .col-lg-2 {
      flex: 0 0 20% !important;
      max-width: 20% !important;
  }
   .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1300px !important;
  }
}


@media #{$extra-device}{
  .hero-slider--business .slide-content {
      margin-top: 340px !important;
  }
  .creative-portfolio .tp-caption .playfair-font {
    font-size: 50px;
  }
  .hero-slider--business .slide-content h2 {
    font-size: 60px !important;
  }  
   .endtoendWrap .col-lg-2 {
      flex: 0 0 20% !important;
      max-width: 20% !important;
  }
   .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1300px !important;
  }
}

@media #{$laptop-device}{

  .creative-portfolio .tp-caption .playfair-font {
    font-size: 38px;
    max-width: 550px;
  }
  .minimal-gallery.masonry-wrap .masonary-item {
    padding: 0 20px;
    margin-top: 40px;
  }
  .minimal-gallery.masonry-wrap {
    margin: 0 -20px;
  }

  .hero-slider--business .slide-content h2 {
    font-size: 50px !important;
  }  
   .endtoendWrap .col-lg-2 {
      flex: 0 0 20% !important;
      max-width: 20% !important;
  }

  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1300px !important;
  }
    .columnWhatwedoWrap{
      height: 460px !important;
  } 
  #howwework h2{
  width: 100%;
  }  
  #howwework{
    padding-top: 300px !important;
    padding-bottom: 50px !important;
  }
  #endtoend{
    padding-top: 100px !important;
  }
 .lineDivider{
  display:none;
} 
}

@media #{$lg-layout}{
  .creative-portfolio .tp-caption .playfair-font {
    font-size: 36px;
    max-width: 550px;
    left: -20px;
    position: relative;
  }

  .revolution-slider-area .inner h1.heading {
    font-size: 89px;
  }
  .one-page-header .header-wrapper .header-left-wrapper {
    padding-left: 43px;
    padding-right: 43px;
  }
  .presentation-slider-wrapper .presentation-inner .inner.skill-area {
    padding-left: 30px;
    padding-right: 30px;
  }
  .single-product-details-side {
    margin-left: 16px;
  }
  .endtoendWrap .col-lg-2 {
    flex: 0 0 19.666667% !important;
    max-width: 19.666667% !important;
  }
  .squareImg {
      text-align: right;
      display: block;
      margin: 0 auto;
      width: auto;
      text-align: right;
      position: inherit;
      top: 0;
      transform: none;
      -ms-transform: no;
      margin: 0 auto;
      text-align: center;
      left: 0;
  }  
    .columnWhatwedoWrap{
      height: 350px !important;
  }
   #howwework{
    padding-top: 230px !important;
    padding-bottom: 50px !important;
  } 
  .hero-slider--business .slide-content h2 {
      font-size: 50px;

  }  
  .hero-slider .slide-inner .container-fluid{
    padding: 0 0 !important;
  }
  .slide-content-bottom{
    padding-left: 50px !important;
  }  
  .mapgImgWrap{
    height: 210px;
  }
  #whatwedo{
      padding-bottom: 70px;
  }
  #howwework h2{
  width: 100%;
  }
.verticalTbs .nav-item a{
  line-height: 30px !important;
} 

.infoTeamTop{
  display: block;
  margin-bottom: 20px;
}
.infoTeamBottom{
  display: none;
}
.teamDescription{
  margin-top: 60px;
  margin-bottom: 20px;
}
.lineDivider{
  display: none !important;
}
.lineDivider{
  display:none;
}
 
}


@media #{$md-layout}{

  .revolution-slider-area .inner h1.heading {
    font-size: 70px !important;
  }

  .one-page-header .header-wrapper .header-left-wrapper {
    padding-left: 43px;
    padding-right: 43px;
  }
  .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .post-character {
    font-size: 220px;
  }
  .blog-style--1 .blog-content .content h3.the-title {
    font-size: 20px;
  }
  .blog-grid.blog-standard .post-content {
    padding: 29px 12px 30px;
  }
  .endtoendWrap .col-lg-2 {

  }
  .dividerPortfTwoHidden{
    display:none;
  }

  .squareImg {
      text-align: right;
      display: block;
      margin: 0 auto;
      width: auto;
      text-align: right;
      position: inherit;
      top: 0;
      transform: none;
      -ms-transform: no;
      margin: 0 auto;
      text-align: center;
      left: 0;
  }  
  .columnWhatwedoWrap{
      height: 310px !important;
  }
  #howwework{
    padding-top: 171px !important;
  }
  #howwework h2, #endtoend h2 {
    width: 100% !important;
  }
.lineDivider{
  display:none;
}
  .number-wrap:nth-child(2){
    margin-top: 70px !important;
  }
  .number-speaks .content h2{
    margin-bottom: 15px;
  }
  .mapImgContent h3, .h3CounterData{
    margin-bottom: 20px !important;
  }
  .mapImgContent{
    margin-top: 25px;
  }


}

@media #{$sm-layout}{
  .bk-team-area {
      padding-top: 100px;
  }
  .logoList li img {
      max-width: 185px;
  }
  .nav-item {
      margin-bottom: 7px;
  }
  .brook-brand-area {
      background: #ffffff !important;
      padding: 40px 0 !important;
  }
  .bk-testimonial-area .section-pt-xl {
      padding-top: 0px !important;
  }
  .bk-number-speaks .container{
    padding-bottom: 0px !important;
  }

  .columnHowWeWork:first-child{
    border-left:none !important;
  }
  .columnHowWeWork:last-child{
    border-right:none !important;
  }  
  #howwework h2, .bk-whatwedo-area h2, #endtoend h2 {
      width: 100%;
  }
  .servicesDevider{display:none !important;}
  .columnServices {
    border: none;
  }
  .columnServices:first-child {
    border: none;
  }
  .slick-dot-vertical-center .slick-dots {
    right: 10px;
  }
  .authentic-service h1 br {
    display: none;
  }
  .revolution-slider-area .inner h1.heading {
    font-size: 70px !important;
  }
  .client-succeed h3.heading-h3.font-32 {
    font-size: 20px;
    line-height: 1.88;
    margin-top: 20px;
  }
  .tp-caption.startups-pra,
  .tp-caption.creative-agency-pra {
    padding: 0 29px !important;
    width: auto !important;
  }

  .tp-caption.homer-service-heading {
    padding: 0 84px !important;
    width: 100% !important;
    top: 9px;
  }
  .tp-caption.homer-service-pra {
    padding: 0 84px !important;
    width: 100% !important;
    top: 35px;
  }
  .tp-caption.creative-agency-heading {
    font-size: 38px !important;
    padding: 0 30px !important;
  }

  .tp-caption.product-landing-pra {
    font-size: 14px !important;
    padding: 0 30px !important;
  }
  .architecture-service p.bk_pra {
    color: #ffffff;
  }
  .tp-caption.design-studio-pra {
    font-size: 14px !important;
    padding: 0 30px !important;
    position: relative;
    top: 15px;
  }
  .tp-caption.architecture-pra {
    font-size: 14px !important;
    padding: 0 30px !important;
    position: relative;
    left: -36px !important;
    padding-right: 51px !important;
    top: -77px;
  }

  .tp-caption.architecture-btn {
    position: relative;
    top: -50px !important;
  }

  .single-vertical-slide .slide-inner .info h2.heading {
    font-size: 36px;
  }
  .revolution-slider-area .inner h1.heading br,
  h3.heading-h3 br,
  .single-vertical-slide .slide-inner .info h2.heading br {
    display: none;
  }
  h6.heading-h6.font-16 {
    font-size: 14px;
  }
  .brand-default.brand-style--2 .brand {
    min-height: 144px;
  }
  .section-title--between .title h3,
  .title--creative h2 {
    font-size: 29px;
  }
  .call-to-action.call-to-action--1 .content h3 {
    font-size: 32px;
    margin-bottom: 35px;
  }
  .portfolio.portfolio_style--2 .port-overlay-info .content {
    padding: 37px 20px;
  }
  .portfolio.portfolio_style--2 .port-overlay-info .content h3.port-title {
    font-size: 18px;
  }

  .one-page-header .header-wrapper .header-left-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
  .shop-sidebar-container .shop-sidebar-wrapper {
    padding: 50px 10px 66px;
  }
  .number-wrap span {
    font-size: 41px;
  }
  .testimonial--horizontal--active {
    padding-right: 30px;
  }
  .number-speaks .content h2 {
    font-size: 37px;
    margin-bottom: 20px;
  }
  .blog-style--1 .blog-content.blog-position--bottom {
    padding: 30px 18px 10px;
  }

  .blog-style--1 .blog-content .content h3.the-title {
    font-size: 16px;
    margin: 0 0 13px;
  }
  .blog-style--1 .blog-content .content ul.meta {
    margin-bottom: 14px;
  }
  .blog-style--1 .blog-content .content .video-btn {
    margin-bottom: 22px;
  }
  .single-motive-speech .content p {
    padding-right: 0;
  }
  .authentic-service h1.heading-h1 {
    font-size: 24px !important;
  }
  .dividerPortfTwoHidden{
    display:none;
  }
  .squareImg {
      text-align: right;
      display: block;
      margin: 0 auto;
      width: auto;
      text-align: right;
      position: inherit;
      top: 0;
      transform: none;
      -ms-transform: no;
      margin: 0 auto;
      text-align: center;
      left: 0;
  }  
  .columnWhatwedoWrap{
     background-image: url(../../img/content/supplychainMobile.png) !important;
      height: 610px;
  }

  #howwework{
    padding-top: 171px !important;
  }
  .lineDivider{
    display:none;
  }
  .portfolioItem1{}

  .portfolioItem1 .thumb{
      height: 343px;
      overflow: hidden;
  }

  .portfolioItem1 .thumb img{
      width: 200% !important;
      max-width: 200%;
      margin-left: -200px;
  }

.columnHowWeWork{
  margin-bottom: 40px;
}


}

@media #{$large-mobile}{
.FooterPopU2 {
    display: none;
    background: white;
    color: black;
    padding: 40px;
    width: 100%;
    position: absolute;
    z-index: 9110;
    HEIGHT: 500px;
    left: 0;
    margin-left: 0;
    top: 0;
    margin-top: 0;
}
    .copyright.copyright--2 .copyright-right {
      width: 100%;
    }
    .testimonial--horizontal--active .swiper-pagination.swiper-pagination-custom {
      width: 245px;
    }
    .porfolio-swip-horizontal .swiper-slide .thumb img {
      height: 404px;
    }
    .servicesDevider{display:none !important;}
    .columnServices {
      border: none;
    }
    .columnServices:first-child {
      border: none;
    }
    .dividerPortfTwoHidden{
      display:none;
    }
  .squareImg {
      text-align: right;
      display: block;
      margin: 0 auto;
      width: auto;
      text-align: right;
      position: inherit;
      top: 0;
      transform: none;
      -ms-transform: no;
      margin: 0 auto;
      text-align: center;
      left: 0;
  } 
  .section-title h2,
  .number-speaks .content h2 {
      font-size: 26px;
      margin-bottom: 25px;
      text-align: center;
  }
  .bk-whatwedo-area h2{
    width: 100% !important;
  }
  .swiper-container {
    background-image: url('../../img/revolution/bigKeyMB.png') !important;

  }
  .header-default.header-transparent {
      background: white !important;
  }
  .hero-slider--business .slide-content h2 {
      color: #ffffff!important;
  }
  .hero-slider--business .slide-content h2 .h2-sub1{
    color: white !important;
  }
  .header-default .header__wrapper .header-right{
    margin: 0 0 !important;
  }
  .hero-slider--business .slide-content{
    margin-left: 20px !important;
  }
  .container {
      max-width: 375px !important;
          padding-right: 15px;
    padding-left: 15px;
  }
  .mapImgContent h3, .h3CounterData {
      margin-bottom: 25px !important;

  }
  .h3CounterData, .mapImgContent h3{
    text-align: center;
  }
  .counterUpContentP {
      width: 100% !important;
  }
  .mapImgContent h3{
     margin-bottom: 25px !important;
     margin-top: 25px !important;
   }
  .number-wrap .content {
      max-width: 100% !important;
      flex: 0 0 80% !important;
  } 

  .mapgImgWrap{
      background-image: url(../../img/bg/bg-image-3-responsive.png) !important;
      height: 563px !important;
  }
  .bk-number-speaks .container{
    padding-bottom: 30px !important;
  }

.columnWhatwedoWrap{
   background-image: url(../../img/content/supplychainMobile.png) !important;
    height: 550px;
}
#howwework {
    padding-top: 100px !important;
}
#endtoend h2 {
    width: 100% !important;
}
.endtoendWrap section{
    margin-bottom: 50px !important;
}
.endtoendWrap h4{
  margin-top: 0px !important;
}
.portfolio-wrapper-icons{
      padding-left: 15px !important;
      padding-right: 15px !important;
}

.bgImageProj {
    background-position: 0 !important;
}
.verticalTbs .nav-item{
  border-left: 0 !important;
  margin-bottom: 10px;
}
.verticalTbs .nav-item a {
    padding: 0 0 !important;
}

closeProjects span {
    font-size: 13px !important;
}
.col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
}
.brook-brand-area {
    padding: 35px 0 !important;
}

.pillsWrapTabs .nav-item a{
  line-height: 30px !important;
}

.logoList {
    display: block !important;
    text-align: center !important;
}.logoList li {
     margin-right: 0 !important; 
     line-height: 100px !important; 
}

.bk-testimonial-area h2{
  margin-bottom: 0px !important;
}
.testimonial-wrapper{
  margin-top: 0px !important;
}

#endtoend .container{
    padding-right: 30px !important;
    padding-left: 30px !important ;  
}
.infoTeamTop{
  display: block;
  margin-bottom: 20px;
}
.infoTeamBottom{
  display: none;
}

.teamDescription{
  margin-top: 20px;
  margin-bottom: 20px;
}
.lineDivider{
  display: none !important;
}
.lineDivider{
  display:none;
}
.columnHowWeWork{
  margin-bottom: 40px;
}



}

@media #{$small-mobile}{
  .servicesDevider{display:none !important;}
  .columnServices {
    border: none;
  }
  .columnServices:first-child {
    border: none;
  }
  .dividerPortfTwoHidden{
    display:none;
  }
  .squareImg {
      text-align: right;
      display: block;
      margin: 0 auto;
      width: auto;
      text-align: right;
      position: inherit;
      top: 0;
      transform: none;
      -ms-transform: no;
      margin: 0 auto;
      text-align: center;
      left: 0;
  }
  .section-title h2,
  .number-speaks .content h2 {
      font-size: 26px;
      margin-bottom: 25px;
      text-align: center;
  }
  .bk-whatwedo-area h2{
    width: 100% !important;
  }
  .swiper-container {
    background-image: url('../../img/revolution/bigKeyMB.png') !important;

  }
  .header-default.header-transparent {
      background: white !important;
  }
  .hero-slider--business .slide-content h2 {
      color: #ffffff!important;
  }
  .hero-slider--business .slide-content h2 .h2-sub1{
    color: white !important;
  }
  .header-default .header__wrapper .header-right{
    margin: 0 0 !important;
  }
  .hero-slider--business .slide-content{
    margin-left: 20px !important;
  }
  .container {
      max-width: 375px !important;
          padding-right: 15px;
    padding-left: 15px;
  }
  .mapImgContent h3, .h3CounterData {
      margin-bottom: 25px !important;

  }
  .h3CounterData, .mapImgContent h3{
    text-align: center;
  }
  .counterUpContentP {
      width: 100% !important;
  }
  .mapImgContent h3{
     margin-bottom: 25px !important;
     margin-top: 25px !important;
   }
  .number-wrap .content {
      max-width: 100% !important;
      flex: 0 0 80% !important;
  } 

  .mapgImgWrap{
      background-image: url(../../img/bg/bg-image-3-responsive.png) !important;
      height: 563px !important;
  }
  .bk-number-speaks .container{
    padding-bottom: 30px !important;
  }

  .columnWhatwedoWrap{
     background-image: url(../../img/content/supplychainMobile.png) !important;
      height: 550px;
  }
  #howwework {
      padding-top: 100px !important;
      padding-bottom: 0px !important;
  }
  #endtoend h2 {
      width: 100% !important;
  }
  .endtoendWrap section{
      margin-bottom: 50px !important;
  }
  .endtoendWrap h4{
    margin-top: 0px !important;
  }
  .portfolio-wrapper-icons{
        padding-left: 15px !important;
        padding-right: 15px !important;
  }

  .bgImageProj {
      background-position: 0 !important;
  }
  .verticalTbs .nav-item{
    border-left: 0 !important;
    margin-bottom: 10px;
  }
  .verticalTbs .nav-item a {
      padding: 0 0 !important;
  }

  closeProjects span {
      font-size: 13px !important;
  }
  .col-12 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
  }
  .brook-brand-area {
      padding: 35px 0 !important;
  }

  .pillsWrapTabs .nav-item a{
    line-height: 30px !important;
  }

  .logoList {
      display: block !important;
      text-align: center !important;
  }.logoList li {
       margin-right: 0 !important; 
       line-height: 100px !important; 
  }

  .bk-testimonial-area h2{
    margin-bottom: 0px !important;
  }
  .testimonial-wrapper{
    margin-top: 0px !important;
  }

  #endtoend .container{
      padding-right: 30px !important;
      padding-left: 30px !important ;  
  }
  .infoTeamTop{
    display: block;
    margin-bottom: 20px;
  }
  .infoTeamBottom{
    display: none;
  }

  .teamDescription{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .lineDivider{
    display: none !important;
  }

  .ClientLogoNine{
    max-width:60px !important;
  }

  .lineDivider{
    display:none;
  }

  .portfolioItem1{}

  .portfolioItem1 .thumb{
      height: 343px;
      overflow: hidden;
  }

  .portfolioItem1 .thumb img{
      width: 200% !important;
      max-width: 200%;
      margin-left: -200px;
  }

  .columnHowWeWork{
    margin-bottom: 40px;
  }

}


@media #{$custom-lg-layout} {
  .header-default.light-logo--version .mainmenu-wrapper .page_nav ul.mainmenu li a{
    font-size: 9px !important;
  }

}
