/* ========================
Portfolio Details 
===========================*/

.bg_image--38 {
    background-image: url(../../img/bg/bg-image-38.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}


.bk-portfolio-area{
    background: #ffffff !important;
}







.portfolio-details-list{
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 62px 30px;
    .details-list{
        label{
            color: #222222;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 12px;
        }
        span{
            color: #999;
            font-weight: 500;
            letter-spacing: 0em;
            line-height: 1.58;
            font-size: 14px;
            display: block;
            a{
                color: #999;
                transition: 0.4s;
                &:hover{
                    color: $theme-color;
                }
            }
        }
    }
}





/*==========================
Portfolio Nav Page 
============================*/

.portfolio-nav-list{
    display: flex;
    justify-content: space-between;

    @media #{$sm-layout} {
        display: block;
    }

    .portfolio-page{
        .inner{
            a{
                display: block;
                color: #999;
                -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
                transition: all .3s cubic-bezier(.645,.045,.355,1);
               
                h3{
                    line-height: 1.42;
                    margin: 22px 0 0;
                    position: relative;
                    &::before{
                        position: absolute;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                        font-size: 24px;
                        font-family: 'FontAwesome';
                        font-weight: 900;
                    }
                }

                &:hover{
                    color: $theme-color;
                }
            }
        }
        &.prev{
            padding-left: 100px;
            text-align: left;

            @media #{$md-layout} {
                padding-left: 60px;
            }

            @media #{$sm-layout} {
                padding-left: 60px;
            }

            .inner{
                a{
                    
                    h3{
                        &::before{
                            left: -100px;
                            content: "\f060";
                            @media #{$md-layout} {
                                left: -60px;
                            }
                            @media #{$sm-layout} {
                                left: -60px;
                            }
                        }
                    }
                }
            }
        }
        &.next{
            padding-right: 100px;
            text-align: right;

            @media #{$md-layout} {
                padding-right: 60px;
            }

            @media #{$sm-layout} {
                padding-right: 60px;
            }

            .inner{
                a{
                    h3{
                        &::before{
                            right: -100px;
                            content: "\f061";

                            @media #{$md-layout} {
                                right: -60px;
                            }

                            @media #{$sm-layout} {
                                right: -60px;
                            }
                        }
                    }
                }
            }
        }
    }
}


.portfolio-wrapper-icons{
    background: #3C3C3C;
}

.portfolio-wrapper-icons .row{
    text-align: center;
    height: 100%;
}
.portfolio-wrapper-icons .row .col-6:first-child{

}
.portfolio-wrapper-icons .row .column h4{
    color: white;
    font-size: 18px;
        padding-top: 22%;
}
.portfolio-wrapper-icons .row .column p{
    color: white;
    font-size: 16px;
    width: 65%;
    text-align:center;
    margin: 24px auto 0 auto;    
}

.dividerPortfOne{
    height: 233px;
    width: 38px;
    position: absolute;
    top: 50%;
    margin-top: -117px;
    right: -19px;
}




.dividerPortfTwo{
    width: 233px;
    height: 38px;
    position: absolute;
    bottom: -18px;
    left: 50%;
    margin-left: -117px;
}
.col-6-last{
    
}
.bk-portfolio-area .black-bg-wrapper{
    padding-bottom: 178px !important;
}


