/*------------------GOIKID CORPORATE FONT FAMILY -----------------------*/

@font-face {
    font-family: 'Univers LT Std';
    src: url('../fonts/UniversLTStd-Bold.woff2') format('woff2'),
        url('../fonts/UniversLTStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers';
    src: url('../fonts/Univers-Black.woff2') format('woff2'),
        url('../fonts/Univers-Black.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std';
    src: url('../fonts/UniversLTStd.woff2') format('woff2'),
        url('../fonts/UniversLTStd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers-Light-Normal';
    src: url('../fonts/Univers-Light-Normal.woff2') format('woff2'),
        url('../fonts/Univers-Light-Normal.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/*------------------GOIKID CORPORATE FONT FAMILY -----------------------*/


@font-face {
    font-family: 'louis_george_cafebold_italic';
    src: url('../fonts/louis_george_cafe_bold_italic-webfont.woff2') format('woff2'),
         url('../fonts/louis_george_cafe_bold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'louis_george_cafebold';
    src: url('../fonts/louis_george_cafe_bold-webfont.woff2') format('woff2'),
         url('../fonts/louis_george_cafe_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'louis_george_cafeitalic';
    src: url('../fonts/louis_george_cafe_italic-webfont.woff2') format('woff2'),
         url('../fonts/louis_george_cafe_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'louis_george_caf_lightitalic';
    src: url('../fonts/louis_george_cafe_light_italic-webfont.woff2') format('woff2'),
         url('../fonts/louis_george_cafe_light_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'louis_george_cafe_lightRg';
    src: url('../fonts/louis_george_cafe_light-webfont.woff2') format('woff2'),
         url('../fonts/louis_george_cafe_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'louis_george_caferegular';
    src: url('../fonts/louis_george_cafe-webfont.woff2') format('woff2'),
         url('../fonts/louis_george_cafe-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
