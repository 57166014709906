/* ======================
    Service Css 
=========================*/

.service {
  &.service--1 {
    position: relative;
    padding: 60px 35px 64px;
    background: #fff;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    z-index: 1;

    &::before {
      position: absolute;
      content: "";
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      border: 1px solid #eee;
      opacity: 1;
      visibility: visible;
      z-index: -1;
    }

    &:hover {
      -webkit-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px);
      box-shadow: 0 2px 30px rgba(0, 0, 0, .1);

      &::before {
        border-color: #0038E3;
      }
    }

    .icons {
      margin-bottom: 22px;

      i {
        color: $theme-color;
        font-size: 80px;
      }
    }

    .content {
      h4 {
        font-size: 18px;
        line-height: 1.38;
        margin-bottom: 18px;
        color: $heading-color;
        font-family: $bodyFont;
        font-weight: 600;
      }

      p {
        color: #333;
        font-family: 'louis_george_caferegular';
        letter-spacing: 0em;
        line-height: 1.32;
        font-size: 16px;
      }

      a {
        &.service-btn {
          border: 0;
          background-color: transparent;
          font-weight: 600;
          font-size: 14px;
          color: #222;
          display: inline-block;
          margin-top: 46px;
          padding-right: 25px;
          transition: 0.3s;

          i {
            font-size: 12px;
            left: auto;
            right: auto;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
            margin-left: 15px;
            color: $theme-color;
            transition: 0.3s;
          }

          &:hover {
            color: #0038E3;

            i {
              color: #0038E3;
            }
          }
        }
      }
    }
  }

  &.service--2 {
    .thumb {
      margin-bottom: 35px;

      img {
        -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
        transition: all .3s cubic-bezier(.645, .045, .355, 1);
      }
    }

    .content {
      h4 {
        color: #fff;
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 21px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
      }

      p {
        color: #ffffff;
        max-width: 320px;
        font-weight: 500;
        letter-spacing: 0em;
        line-height: 1.6;
        font-size: 15px;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 0;
      }
    }

    &:hover {
      .thumb {
        img {
          -webkit-transform: translateY(-5px);
          -ms-transform: translateY(-5px);
          transform: translateY(-5px);
        }
      }
    }
  }

  // Service STyle 3
  &.service--3 {
    .thumb {
      overflow: hidden;

      a {
        img {
          -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
          transition: all .3s cubic-bezier(.645, .045, .355, 1);
        }
      }
    }

    .content {
      padding: 0 20px;
      text-align: center;

      h3 {
        font-size: 18px;
        margin-bottom: 14px;
        margin-top: 28px;
        font-weight: 600;
        letter-spacing: 0em;
        line-height: 1.23;
        font-family: $bodyFont;

        a {
          color: $heading-color;
          -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
          transition: all .3s cubic-bezier(.645, .045, .355, 1);

          &:hover {
            color: $theme-color;
          }
        }
      }

      p {
        letter-spacing: 0em;
        line-height: 1.32;
        font-size: 16px;
        font-family: $heading-font;
        color: $bodyColor;
      }
    }

    &:hover {
      .thumb {
        a {
          img {
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
          }
        }
      }
    }
  }


}


.slick-btn.slick-arrow i {
  font-size: 30px;
  color: #f10;
}


// Service List

.bk-service-list {
  .thumb {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    max-width: 40%;

    @media #{$sm-layout} {
      max-width: 100%;
      margin-bottom: 30px;
    }

  }

  .content {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 890px;
  }
}


/* Architecture Service */

.architecture-service-container {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  transition: all 1s;
}

.authentic-service {
  h1 {
    &.heading-h1 {
      @media #{$lg-layout} {
        font-size: 37px;
      }
    }
  }
}

.architecture-bg-thumbnail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  .inner {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);

    .architecture-hover-image {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transition: all 1.5s;
      transition: all 1.5s;
      opacity: 0;
      z-index: -1;
      overflow: hidden;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;


      &.active {
        z-index: 1;
        opacity: 1;
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
      }

      .bg-thumb {
        -webkit-transition: all 1s;
        transition: all 1s;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        &.bg-image--1 {
          background-image: url(../../img/slider/type/bg-image-19.jpg)
        }

        &.bg-image--2 {
          background-image: url(../../img/slider/type/bg-image-20.jpg)
        }

        &.bg-image--3 {
          background-image: url(../../img/slider/type/bg-image-21.jpg)
        }

        &.bg-image--4 {
          background-image: url(../../img/slider/type/bg-image-22.jpg)
        }

      }
    }
  }
}


.architecture-service {
  min-height: 650px;
  border-right: 1px solid hsla(0, 0%, 100%, .21);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: relative;
  z-index: 9;

  -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
  transition: all .3s cubic-bezier(.645, .045, .355, 1);

  @media #{$md-layout} {
    min-height: auto;
    height: auto;
    padding: 0 30px;
  }

  @media #{$sm-layout} {
    min-height: auto;
    height: auto;
    padding: 0 30px;
  }


  .architecture-inner {
    padding: 97px 50px;

    @media #{$lg-layout} {
      padding: 60px 0px;
    }

    @media #{$laptop-device} {
      padding: 97px 19px;
    }

    @media #{$md-layout} {
      padding: 60px 0px;
    }

    @media #{$sm-layout} {
      padding: 60px 0px;
    }

    .view-more-btn {
      height: 0;
      opacity: 0;
      visibility: hidden;
      -webkit-transform: translateY(10px);
      -ms-transform: translateY(10px);
      transform: translateY(10px);
      -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
    }
  }

  &:hover {
    .architecture-inner {
      .view-more-btn {
        height: auto;
        opacity: 1;
        visibility: visible;
        margin-top: 24px;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
      }
    }
  }
}

.architecture-service .architecture-inner p {
  color: #fff;
}
.columnServices:hover{


}
.columnServices{
  padding:0px 0;
  border-right: 1px solid #00afc6;
}
.columnServices:first-child {
  border-left: 1px solid #00afc6;
}
.columnServices .mt--30{
  margin-top:0;
}
.servicesDevider{
    border-top: 1px solid #00afc6;
    width: 30px;
    display: block;
    position: absolute;
    top: -50%;
    margin-top: 209px;
    left: -15px;
}
.columnServices:first-child .servicesDevider{
  display:none;
}
.bk-service-area h2{
  width: 100%;
  margin: 0 auto;
}

/*********** WHAT WE DO AREA ***************/
#whatweare{
  background: #f5f5f5 !important;
}
.bk-whatwedo-area h2{
  width: 75%;
  text-align: center;
  margin: 0 auto;
}
/*********** END TO END AREA ***************/

.endtoendWrap {
  color: #000 !important;
  color: #000 !important;
  border-bottom: 1px solid #00afc6;
  padding-bottom: 20px;
}

.headerNumber span{
  font-size: 25px;
}
.endtoendWrap h4{
  margin-top: 40px;
  text-transform:uppercase;
  font-size: 17px;
}
#endtoend h2{
  width:60%;
  margin-bottom: 100px;
}
.arrowBorder{
    background-image: url(../../img/slider/type/arrowBorder.png);
    display: block;
    height: 10px;
    background-repeat: no-repeat;
    background-position: right 0.0732em;  
    
}
.arrowLine{
    border-top: 1px solid #00afc6;
    width: 99%;
    position: relative;
    top: 0.2607em;
}
.arrowBlue{}

.endtoendWrap ul {
    margin: 20px 0 0 0;
    padding: 0;
}
.endtoendWrap ul li{
    background-image: url(../../img/slider/type/plus.png);
    background-repeat: no-repeat;
    display: block;
    padding: 22px 0 17px 0px;
    
}
.headerIcon{
  overflow:hidden;
}
.headerIcon img{
  float: right;
}
#endtoend h4{
  
}
#endtoend h5{
      color: #00afc6;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 15px;
      padding-top: 20px;
}
.endtoendWrap ul li::selection,
.endtoendWrap h5::selection,
.endtoendWrap h4::selection{
  background-color: #00afc6;
  color:white;
}
.endtoendWrap ul li::-moz-selection,
.endtoendWrap h5::-moz-selection,
.endtoendWrap h4::-moz-selection{
  background-color: #00afc6;
  color:white;
}
/*********** HOW WE WORK ***************/
#howwework{
      background: url(../../img/content/howweworkbg.png) no-repeat 0 0 !important;
      background-color: #fff !important;
      background-size: 100% !important;
      padding-top: 380px !important;
}

#howwework .container{
  padding-top: 0;
}
#howwework  h2{
  width: 54%;
}
#howwework .icon-box .inner .icon > i{
    
    height: 75px;
    display: block;
    background-repeat: no-repeat;
    text-align: center;
    margin: 0 auto; 
}
.leadership{
  background-image: url(../../img/icons/leadership.png);
  width: 119px !important;
}
.method{
  background-image: url(../../img/icons/method.png);
  width: 74px !important;
}
.solutions{
  background-image: url(../../img/icons/solutions.png);
  width: 70px !important;
}
.subject{
  background-image: url(../../img/icons/subject.png);
  width: 65px !important;
}
.columnHowWeWork{
  position: relative;
}
.columnHowWeWork:first-child .servicesDevider{
  display:none;
}
.columnHowWeWork:first-child{
  border-left: 1px solid #00afc6 !important;
}
.columnHowWeWork:last-child{
  border-right: 1px solid #00afc6 !important;
}

.columnHowWeWork .icon-box{
  margin-top: 0px;
}
.howWeWorkWrap .servicesDevider{
    border-top: 1px solid #00afc6 !important;
    width: 180px;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: 0;
    left: -90px;

}
.servicesVerticalDevider{
    border-right: 1px solid #00afc6 !important;
    height: 59px;
    display: block;
    margin: 10px auto 20px auto;
    text-align: center;
    width: 1px;
}
.columnHowWeWork h5{
}

.social-client{
  display: block !important;
}

.brook-section-title h2{
  font-weight: normal;
  color: #000000 !important;
  font-size: 34px !important;
}

.enlaceTest{
  font-sixe: 100px !important;
  color: #000 !important;
}

.bk-whatwedo-area{
  background: #ffffff !important;
}
#whatwedo{
  padding-top:100px !important;
}
.bk-number-speaks{
  padding-bottom: 0px !important;
}


.is-sticky.sticky {
  z-index: 115 !important;
}


.portfolio-wrapper-icons {
    text-align: right;
}
.portfolio-wrapper-icons .row{
    text-align: right}

.portfolio-wrapper-icons .row .column{
    text-align: right;
    position: relative;
}
.portfolio-wrapper-icons .row .column img{
    text-align: right;
}
.squareImg{
    text-align: right;
    display: block;
    margin: 0 auto;
    width: 80%;
    text-align: right;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    margin: 0 auto;
    text-align: center;
    left: 50%;  
}



.dividerPortfTwoHidden{
  display:block;
}
.menu-hamburger div i {
    color: #3c3c3c;
}
.port-overlay-info{
  cursor: pointer;
}

/*************** PORTFOLIO AREA ***************/
.detailProjectsWrap P{
  color: #000 !important;
  font-size: 18px;
  font-family: "Univers LT Std";
  
}
.detailProjectsWrap{
  margin-top: 60px;
  padding-bottom: 0px !important;
}

.bgImageProj{
    height: 240px;
    background-position: -50%;
    margin-bottom: 50px;
    background-repeat: no-repeat;
    background-size: cover; 
}
.bgImageProj1{
    background-image: url(../../img/content/bgImageProj1.jpg);

}
.bgImageProj2{
    background-image: url(../../img/content/bgImageProj2.jpg);
}
.bgImageProj3{
    background-image: url(../../img/content/bgImageProj3.jpg);
}

.bgImageProj h2{
    font-weight: 600;
    font-size: 22px;
    color: white;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

.detailProjectsWrap .tab.vertical .tab-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
.detailProjectsWrap h3{
       font-size: 14px;
      font-weight:normal;
      font-size: 40px; 
}
.detailProjectsWrap h4{
      color: #00afc6;
      font-size: 14px;
      font-weight:normal;
}
.detailProjectsWrap .col-6 {
    flex: 0 0 42% !important;
    max-width: 42% !important;
    padding-left: 60px !important;
    padding-right: 60px !important;
}

.detailProjectsWrap .row{
  justify-content: center;
}

.stepsNumber{
      color: #00afc6;
      font-size: 24px;
      font-weight:normal;  
}

p.blue{
  color: #00afc6 !important;
}

.verticalTbs .tab-nav{
  display: -webkit-inline-box !important;
}
.verticalTbs .nav-pills {
    margin-bottom: 20px !important;
    font-size: 15px !important;
    text-transform: uppercase;
    margin-left: 0 !important;
}

        .tabHide, .tabShow {
            overflow-y: hidden;
            -webkit-transition: max-height 0.8s ease-in-out;
            -moz-transition: max-height 0.8s ease-in-out;
            -o-transition: max-height 0.8s ease-in-out;
            transition: max-height 0.8s ease-in-out;
        }
 
        .tabHide {            
            height: 0px !important;                   
        }  
        .tabShow {            
            height: 100% !important ;                    
        }   
.projectsColumn{
    flex: 0 0 40% !important;
    max-width: 40% !important;
    padding-right: 25px !important;
    padding-left: 25px !important;      
}
.plusIcon{
  background-image: url(../../img/icons/plus.png);
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: 2;
    right: 20px;
    bottom: 20px;
}
.closeProjects{
    font-size: 15px;
    border-radius: 0.25rem;
    display: block;
    color: #000000 !important;
    cursor: pointer;
}

.mapgImgWrap{
    background-image: url(../../img/bg/bg-image-3.png);
    height: 373px;
    display: block;
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat;
}
.columnWhatwedoWrap{
   background-image: url(../../img/content/supplychain.png);
    display: block;
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 515px;
}
.CookieConsent button{
      background: #00afc6 !important;
      color: white !important;
}

.closeProjects span{
  color: #000000 !important;
}
.infoTeamTop{
  display: none;
}

.popup-content{
    width: 80% !important;
    transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    margin: 0 auto;
    text-align: center;
    left: 50% !important;
    height: auto;
    color: black; 
    height: 80%;
}
.FooterPopU{
    height: 100%;
}
.FooterPopU section{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    justify-content: flex-start;
    display: flex;
}
.hidden{
  display:none;
}
.showIt{
  display: block;
}
.bk-contact-area h2{
  margin-bottom: 30px !important;
}
.team.team_style--1 .image-wrap .thumb img {
    width: 100%;
    margin-bottom: 20px !important;
}
.popup-mobile-menu{
      opacity: 1 !important;
}
.bk-hover a{
  color: #ffffff !important;
}

.SuccesfullSentMessage{
  color: green;
  font-size: 16px;
  font-weight: bold;
  min-height: 50px;

}
.checkBoxForm{
  display: flex-box;
}
.checkBoxForm input {
    background: red;
    width: 15px !important;
    height: 15px !important;
    border: 1px solid black !important;
    color: black !important;
}
.checkBoxForm span{
  color: black !important;
  font-size: 14px;
}

.coreValuesContainer{
  margin-top: 150px;
}
.columnServices1:nth-child(5),
.columnServices1:nth-child(6),
.columnServices1:nth-child(7),
.columnServices1:nth-child(8),
.columnServices2:nth-child(1),
.columnServices2:nth-child(2),
.columnServices2:nth-child(3),
.columnServices2:nth-child(4){
  display:none;
}



.ion-ios-craft-outline {
    background: url(../../img/icons/ceramica.png);
}
.ion-ios-caring-outline {
    background: url(../../img/icons/caring.png);

}

.ion-ios-grit-outline{
  background: url(../../img/icons/mountain.png);
}
.icon-innovation{
  background: url(../../img/icons/bombilla.png);
}

.coreValuesContainer .columnServices:nth-child(5){
      border-left: 1px solid #00afc6;
}

.coreValuesContainer .columnServices:nth-child(5) .servicesDevider{
  display: none;
}
.ClientLogoTwentyFive{
  width: 80% !important;
}


.openPrivacy{
    background-color: #00afc6 !important;
    padding: 0.6rem 1rem !important;
    cursor: pointer;
    font-weight: bold !important;
    font-size: 14px !important;
    margin-right: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}
.FooterPopU2{
  display: none;
    background: white;
    color: black;
    padding: 40px;
    width: 50%;
    position: absolute;
    z-index: 9110;
    HEIGHT: 500px;
    left: 50%;
    margin-left: -25%;
    top:50%;
    margin-top:-250px;
}


.FooterPopU3{
      display: none;
    background: white;
    color: black;
    padding: 40px;
    width: 100%;
    /* position: absolute; */
    z-index: 9110;
    HEIGHT: 500px;
    left: 50%;
    /* margin-left: -25%; */
    top: 0;
    /* margin-top: -250px; */
    margin: 0 auto;
}
.privacyFrom{
  text-decoration:underline;
  font-size: 11px;
}
.closePopup2{
padding-top: 10px;
    display: block;
    padding-bottom: 10px;
    text-align: center;
    cursor:pointer;
}
.closePopup3{
    padding-top: 10px;
    display: block;
    padding-bottom: 10px;
    text-align: center;
    cursor:pointer;
}

.privacyFrom:hover{
  color: black;
  cursor:pointer;
}
.ClientLogoFourteen{
      max-width: 168px !important;
}
