/*================
Tabs Area 
==================*/


.tab,
.scrollspy {
  border: 2px solid #fafafa;
  padding: 30px;
}

.nav-pills{
  margin-bottom: 40px !important;
  font-size: 12px;
}
.nav-pills .nav-link {
  color: $heading-color;
}

.tab-content > .tab-pane {
  font-size: 14px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: $theme-color;
}

.tab {
  &.vertical {
    .tab-nav {
      flex-basis: 200px;
      @media #{$sm-layout} {
        flex-basis: 100%;
      }
    }

    .tab-content {
      flex-basis: calc(100% - 200px);
      padding-left: 30px;
      padding-right: 30px;

      @media #{$sm-layout} {
        flex-basis: 100%;
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
        padding-bottom: 10px;
      }
    }
  }
}


@media (min-width: 768px) {
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
}

.social-client .pt--120{
  padding-top: 0px !important;
} 
.social-client{
  border-bottom: 1px solid #D9D9D9;
}
.nav {
    justify-content: center;
}
.logoList{
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 0;
}

.logoList li{
      margin-right: 50px;

}
.logoList li:last-child{
      margin-right: 0;
}
.logoList li img{
    max-width: 185px;
    min-width: 40px;
    width: 100%;
}
.tab{
  border: none !important;
  padding: 0 !important;
}
.brook-brand-area .brook-section-title{
  margin-bottom: 30px !important;
}
.nav-item{
  border-left: 1px solid #00afc6;
}
.nav-item:first-child{
  border-left: none;
}

.nav-item a{
    background-color: transparent !important;
    color: #00afc6 !important;
    padding: 0 0.7rem !important;
    line-height: 13px;
    vertical-align: middle;
}
.verticalTbs .nav-item:first-child a{
  padding-left: 0 !important;
}
.nav-link.active{
  font-weight: 600;
}
.brook-brand-area{
  background: #ffffff !important;
      padding: 100px 0 !important;
}
.brook-brand-area .container{
}
.tab-content{
  display: flex;
  justify-content: center;
}